.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.countrycursor {
  width: 100%;
  border: 1px solid #e8e6e6;
  padding: 13px 20px;
  outline: none;
  cursor: not-allowed;
  background-color: #f0f0f0;

}

.full-width {

  width: 100%;
  border: 1px solid #e8e6e6;
  padding: 13px 20px;
  outline: none;
}

.full-width:focus {
  border-color: #32c36c;
  border-radius: 4px;
}

.resend-otp-btn1 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background: #f39c12;
  ;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 15px;
  outline: none;
  border: 1px solid #f39c12;
  border-radius: 5px;
  transition: 0.4s ease;
}

.resend-otp-btn1:hover {
  background: #fff;
  color: #e67e22;
}

.tracking-status {
  font-family: 'Segoe UI';
  font-weight: 600;
  color: #000000;
  margin: 0px;
  text-align: left;
}

.tracking-status-sub {
  font-family: 'Segoe UI';
  font-weight: 400;
  color: #000000;
  text-align: left;
  padding: 0px;
  margin: 0px;
}

.font {
  font-family: 'Segoe UI';
  font-weight: 600;
}

.modal-backdrop.show {
  opacity: 0;
}


.menu-list a,
.offcanvas-body .nav-link {
  color: #333;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.menu-list a:hover,
.offcanvas-body .nav-link:hover {
  color: #32c36c;
  text-decoration: none;
}

.header-logo img {
  max-width: 150px;
}

@media (max-width: 992px) {

  .menu-list a,
  .offcanvas-body .nav-link {
    color: #333;
    font-family: 'Arial', sans-serif;
  }
}

.btn-primary {
  background-color: #32c36c;
  border-color: #32c36c;
}

.walletColor {
  background-color: #32c36c;
}

.table {
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;
  margin: 0px;
}

.table tbody tr {
  background-color: #f8f8f8;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  */

}

.table tbody tr td {
  vertical-align: middle;
  font-family: 'saira';
  font-weight: 500;
  font-size: 16px;
  /* letter-spacing: ; */
  line-height: 1.563rem;
  white-space: nowrap;
}

.table th {
  border-bottom: none;
  font-family: 'saira';
  font-weight: 500;
  font-size: 16px;
  line-height: 1.563rem;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background-color: #dcffea;
  z-index: 2;
}

.status-completed {
  color: green;
}

.status-pending {
  color: orange;
}

.amount-positive {
  color: green;
}

.amount-negative {
  color: red;
}

.table-responsive {
  overflow-x: auto;
  /*scrollbar visibility */
  overflow-y: auto;
  max-height: 300px;
  position: relative;
}

.table-responsive::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* header profile dropdown*/
header.style-1 .main-menu .menu-list>li a{
  padding: 5px;
}
 .btn-dropdown{
  background-color: #e7fff1;
  border-radius: 88px;
  height: 52px;
 }
 
 .logout-icon {
  margin-left: 50px;
}